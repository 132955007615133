import React from 'react';
import Img from "gatsby-image"
import { Link } from 'gatsby'
import LayoutComponent from '../shared/layoutContent'
import Button from '../BurgerHero/Button';
function CompHomeComponent({ data, copy }) {
  return (
    <div>
      <LayoutComponent>
        <div className="columns">
          <div className="column is-half comp__text-container ">
            <p className="title">{copy}</p>
           <Link  to={"/deliveroo"}><Button copy="Order A Burger" className='button is-link tab-buttons is-outlined active'>Order</Button></Link>
          </div>
          <div className="column is-half">
            <div className="comp__image-container ">
              <Img fluid={data} imgStyle={{
                objectFit: "cover",
              }} />
            </div>
          </div>
        </div>
      </LayoutComponent>
    </div>
  );
}

export default CompHomeComponent;
