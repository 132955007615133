import React, { useState } from 'react';
import Recipes from '../components/Recipes/Recipes';
import Layout from '../components/shared/layout';
import LayoutContent from '../components/shared/layoutContent';
import CompetitionHeader from '../components/HomePage/CompHeader.js'
import { graphql } from 'gatsby'
import logo from '../images/FSlogosmall.png'
import HomeHero from '../components/HomePage/HomeHero';
import Description from "../components/HomePage/Decriptions";
import logoTextImage from '../images/FStext.png'
import SEO from "../components/seo"
import FollowFooter from '../components/shared/FollowFooter';
import Search from '../components/shared/Search'
import Dropdown from '../components/shared/Dropdown'

function App({ data }) {
  const { foodStyles } = data.contentfulFilters.filters
  const { edges } = data.allContentfulRecipes;
  const { poster } = data.allContentfulCompetition.edges[0].node
  const originalRecipes = removeNode(edges);
  const [filters, setFilter] = useState([])
  const [recipes] = useState(originalRecipes)
  const [searchTerm, setSearchTerm] = useState('')

  function removeNode(item) {
    return item.map(({ node }) => node);
  }
  function addOrRemoveFilter(item) {

    if (filters.includes(item)) {
      const filteredItems = filters.filter(filteredItem => filteredItem !== item)
      setFilter([...filteredItems])

    } else {
      setFilter([...filters, item])

    }
  }

  function getFilteredRecipes() {

     return recipes.filter((item) => {
        return item.name.toUpperCase().includes(searchTerm.toUpperCase())
    }).filter(recipe => {
      if(filters.length === 0) {
        return recipe
      }  else {
        if(recipe.tags) {
          return filters.some(filter => {
            return recipe.tags.includes(filter);
          });
        }
    
      }          
    })
    .map((item, index) => (
      <Recipes filters={filters} key={index} data={item} />
    ))

  }

  const onChange = (e) => {
    const target = e.target.value.toLowerCase()
    setSearchTerm(target)
    
  }



  return (

    <Layout>
      <SEO
        title="foodSlut"
        mainImage={`https://www.foodslut.co.uk${logo}`}
        url={``}


      />

      <HomeHero
        heroImage={logo}
        title="FOODSLUT"
        subtitle="Slutty food for those who just love to slut it up. 
        ‘Remember there is no dish that adding bacon won’t improve.’ 
        "
        logoTextImage={logoTextImage}
      />


      {/* <CompetitionHeader copy="FoodSlut on Deliveroo " data={poster.fluid} /> */}
      <div>
        <LayoutContent>

        <div className=" columns  filters filters-box">
        <div className="column is-2">

        <p>Filter by: </p>
        </div>
          <div className="column is-4">
          <Dropdown  onFilterClick={(item) => addOrRemoveFilter(item)} data={foodStyles} />
          </div>
          <div className="column is-offset-3 is-3">

          <Search type="text" onChange={onChange} />
          </div>

        </div>


        <Description description="Recipes" />
        <div className="columns is-multiline  ">

          {getFilteredRecipes()}

        </div>
        <FollowFooter
          link="https://www.instagram.com/foodslut"
          copy="Follow us on instagram" 
          buttonCopy="FOLLOW"
          
          />
        </LayoutContent>

      </div>
    </Layout>


  )
}


export default App;

export const query = graphql`
  query MyQuery {
    allContentfulRecipes (limit:100) {
      edges {
        node {
          id
          name
          slug
          tags
          mainImage {
            fluid(maxWidth: 300, maxHeight:300){
              src
            ...GatsbyContentfulFluid
          }
          } 
    

        }
      }
    }
    contentfulFilters {
      filters {
        foodStyles {
          heading
          items {
            tag
            description
          }
        }  
      }
    }
    allContentfulCompetition {
      edges {
        node {
          id
          poster {
              fluid(maxWidth: 500, maxHeight:500){
                src
              ...GatsbyContentfulFluid
            }
          }
     

          
        }
      }
}
    
  }`


