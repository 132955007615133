import React from 'react';
import { graphql, StaticQuery } from 'gatsby'
import Img from "gatsby-image"
import Button from '../../components/BurgerHero/Button';
import SnipcartButton from '../Products/SnipcartButton';

const FollowFooter = ({ copy, link, buttonCopy, image, title, price, deliveryDate }) =>  (

<StaticQuery
query={graphql`
query{
    allContentfulAboutPage {
        edges {
          node {
            id
            logo {
                fluid(maxWidth: 100, maxHeight:100){
                  src
                ...GatsbyContentfulFluid
              }
            }
            logoText {
                fluid(maxWidth: 280, maxHeight:150){
                  src
                ...GatsbyContentfulFluid
              }
            }

       
            }
          }
        }
}
`}
render={data => {

    const handleFooterClick = () => {
      
      window.location.href = link
    }

    const {logo, logoText } = data.allContentfulAboutPage.edges[0].node
    return (
        <div className="columns follow-footer tile is-child  ">
            <div className="column follow-footer__content-container ">
                <div className="follow-footer__image-container">
                    <Img fluid={logo.fluid} imgStyle={{
                        objectFit: "contain",

                    }} />
                </div>

            </div>
            <div className="column follow-footer__content-container">
                <div className="follow-footer__image-container">
                    <Img fluid={logoText.fluid} imgStyle={{
                        objectFit: "contain",

                    }} />
                    <p className="subtitle has-white-text">{copy}</p>

                    {price &&
                          <SnipcartButton
                          image={image}
                          price={price}
                          title={title}
                          deliveryDate={deliveryDate}

                        />
                    }
                  
                     {
                     !price && <Button handleClick={handleFooterClick} copy={buttonCopy} />
                     } 
                </div>
            </div>


        </div>
    )
}}
/>
)

export default FollowFooter


