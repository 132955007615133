import React from 'react';
import { Link } from 'gatsby';
import Img from "gatsby-image"
// import classNames from 'classnames'

function Recipes({ data, filters }) {
  const { slug, mainImage, name } = data
  return (
    <div className="column recipe-card is-one-third">
      <Link to={`/recipes${slug}`}>
        <div className="card companies">
          <p className=" is-size-5 has-text-centered">{name}</p>
          <div className="card-image">
            <Img fluid={mainImage.fluid} imgStyle={{
              objectFit: "cover",
            }} />
          </div>

        </div>
      </Link>
    </div>
  );
}

export default Recipes;

