import React from 'react'
import LayoutContainer from '../shared/layoutContent';

function HomeHero({ heroImage, title, subtitle, logoTextImage }) {
    return (
        <div className="home-hero">
            <LayoutContainer>

                <div className="columns">

                    <div className="column vertical-center">
                        <div className="container content-container">
                            <div className="logo-text__image-container">
                                <img alt="foodslut text logo" src={logoTextImage} />
                            </div>
                            <h2 className=" has-text-white subtitle">{subtitle}</h2>
                        </div>
                    </div>
                    <div className="column vertical-center">
                        {
                            heroImage ?
                                <div className="column vertical-center">
                                    <div className="main-image-container vertical-center">
                                        <img
                                            src={heroImage}
                                            alt="logo"
                                            className="logo"
                                        />
                                    </div>
                                </div> : null
                        }
                    </div>
                </div>
            </LayoutContainer>

        </div>
    )
}



export default HomeHero

