import React from 'react';

const Description = ({ description }) => (
    <div className=" section columns is-centered home-subtitle filters descriptions">
        <div className="column is-three-fifths ">
            <p className="title centered">{description}</p>
        </div>
    </div>
)

export default Description;


