import React from 'react'


export default function SnipcartButton({title, image, startDate, price, deliveryDate, size}) {

    return (
        <button
          className="snipcart-add-item modal__buy-button button"
          data-item-id={`4${title}`}
          data-item-price={parseFloat(price)}
          data-item-url={"https://www.foodslut.co.uk/diy-burger.json"}
          data-item-name={title}
          data-item-image={`https:${image.fluid.src}`}
          data-item-custom1-name="Delivery Days"
          data-item-custom1-options={deliveryDate}
          data-item-weight={1500}



      >

        Order
        </button>
    )
}