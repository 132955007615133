import React, { useRef} from 'react'
import './styles/dropdown.css';
import classNames from 'classnames';
import {useDetectOutsideClick} from '../hooks/useDetectOutsideClick'

export default function Dropdown({data, onFilterClick}) {
    const dropdownRef = useRef(null);
    const [isActive, setIsActive] = useDetectOutsideClick(dropdownRef, false);
    const onClick = () => setIsActive(!isActive);

    return (
            <div ref={dropdownRef} className="dropdown">

            <div className="dropdown__button" onClick={onClick}  href="#">
                <span className={classNames('hida', { 'active': isActive })}>Food Style</span>
         </div>
                <div className={classNames('dropdown__list', { 'active': isActive })}>
                <ul >
                    {data[0].items.map((item, idx) => {
                        return(
                            <React.Fragment key={idx}>
                            <label className="dropdown__label"  htmlFor={item.tag}>
                            <li className="dropdown__item">
                            <input className="dropdown___checkbox" onClick={() => onFilterClick(item.tag)} id={item.tag} type="checkbox" value={item.tag} />
                            </li>
                            {item.tag}
                        </label>
                        </React.Fragment>
                        )
                    })}
          
                </ul>
                </div>

            </div>
    )
}
