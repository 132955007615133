import React from 'react';
import classNames from 'classnames';

const Button = ({copy, handleClick}) => (
    <button
    onClick={handleClick}
    className={classNames('button is-link tab-buttons is-outlined button-color',  { 'active': true })} >
      {copy}
    </button>
)

export default Button;
